import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { RootState } from '@/src/store';
import { selectProfileToken } from '../../../_slices/profileTokenSlice';
import ProfileTokenComponent from '../../auth/ProfileToken';
import { setSelectedProfile, selectSelectedProfile } from '../../../_slices/sitesSlice';
import { baseAPIUrl } from '../../../config';
import axios from 'axios';
import moment from 'moment';
import SitesSelect from '../../sites/SitesSelect';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



// Chart
import Chart from 'react-apexcharts';
import { useCustomToolbar } from '../_commons/useCustomToolbar';
import { commonOptions } from '../_commons/chartCommonOptions';
import RevenueLoader from '../../../_helpers/loaders/revenueLoader';
// styles
import { ChartFilter, SitesContainer, ChartTitle, ChartDateWrapper } from '../_commons/chartStyles';
import { DatePickerComponent } from '../../FlexDatePicker/style';
import ArrowRight from '../../../assets/icons/arrow-right.svg'

import { RevenueColorsWrapper } from '../_commons/revenueColors';



function TotalChart(props) {
    const dispatch = useDispatch();
    const selectedProfile = useSelector(selectSelectedProfile);

    const [calendarOpen, setCalendarOpen] = useState(false);

    const initialToday = new Date();
    const [today, setToday] = useState(initialToday);
    const [todayLess1, setTodayLess1] = useState(new Date(initialToday.setDate(initialToday.getDate() - 1)));
    const todayLess2 = new Date(todayLess1);
    todayLess2.setDate(todayLess1.getDate() - 1);





    // DatePicker: Max date to be selected in calendar
    const [maxDate] = useState(todayLess2);


    // DatePicker: Start date to apply on dateRange
    const [startDate, setStartDate] = useState(() => {
        const today = new Date();
        if (today.getDate() === 1) {
            console.log('option 1')
            // if it's the first day of the month, get the first day of the previous month
            return new Date(today.getFullYear(), today.getMonth() - 1, 1);
        } else {
            console.log('option 2')
            console.log('date::', new Date(today.getFullYear(), today.getMonth(), 1))
            // if it's not the first day of the month, get the first day of the current month
            return new Date(today.getFullYear(), today.getMonth(), 1);
        }
    });

    const [endDate, setEndDate] = useState(() => {
        const today = new Date();
        // set date to yesterday
        today.setDate(today.getDate() - 1);
        return today;
    });

    // DatePicker:  update the startDate when clicking the button
    const [pickedDate, setPickedDate] = useState(startDate);

    // Starting and Ending date for fetching data
    const startingDate = moment(startDate).format('YYYY-MM-DDT23:00:00');
    const endingDate = moment(endDate).add(1, "days").format('YYYY-MM-DDT23:00:00');



    const [minTime, setMinTime] = useState(startingDate)
    const [maxTime, setMaxTime] = useState(endingDate)


    const profileToken = useSelector(selectProfileToken);
    const prevProfileTokenRef = useRef(profileToken);

    const loadingProfileToken = useSelector((state) => state.profileToken.loading);

    const firstProfile = selectedProfile?.authorized_profiles?.[0];
    const siteID = selectedProfile?.associated_site?.site_id ? selectedProfile?.associated_site?.site_id : firstProfile?.associated_site?.site_id;
    const siteName = selectedProfile?.associated_site?.site_name ? selectedProfile?.associated_site?.site_name : firstProfile?.associated_site?.site_name;

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        setLoading(true);
        setError(null);

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${profileToken}`,
                    'Content-Type': 'application/json',
                },
            };

            const response = await axios.get(
                `${baseAPIUrl}/sites/${siteID}/revenue-streams/total?starting_time=${startingDate}&ending_time=${endingDate}&format=charts`,
                config
            );
            setData(response.data.events);

        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                setError(`Error: ${err.response.status} - ${err.response.statusText}`);
            } else if (err instanceof Error) {
                setError(`Error: ${err.message}`);
            } else {
                setError('An unknown error occurred while fetching data.');
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    console.log(startingDate)

    let timeInterval = 4 * 60 * 60 * 1000;
    let annotations = [];

    for (let time = moment(startingDate).valueOf(); time < moment(endingDate).valueOf(); time += timeInterval) {
        annotations.push({
            x: time,
            strokeDashArray: 0,
            borderColor: '#600C66',
            borderWidth: '1px'
        });
    }

    const handleButtonClick = () => {
        setCalendarOpen(false);
        setMinTime(startingDate);
        setMaxTime(endingDate);
        fetchData();
    };


    const humanDate = () => {
        let start = moment(startingDate).format('D MMMM yyyy')
        let end = moment(endingDate).format('D MMMM yyyy')
        return `Data from ${start} to ${end}`
    }

    const chartData = {
        options: {
            chart: {
                ...commonOptions.chart
            },
            fill: {
                ...commonOptions.fill
            },
            tooltip: {
                ...commonOptions.tooltip
            },
            plotOptions: {
                ...commonOptions.plotOptions
            },
            legend: {
                ...commonOptions.legend
            },
            dataLabels: {
                ...commonOptions.dataLabels
            },
            yaxis: {
                decimalsInFloat: 0,
                title: {
                    text: "REVENUE £",
                    style: {
                        color: '#600C66',
                        fontSize: '14px'
                    }
                }
            },
            xaxis: {
                tickPlacement: 'on',
                labels: {
                    formatter: function (value) {
                        var time = moment(value).format('DD/MM');
                        return time;
                    },
                },
            }
        },
        series: []
    };

    return (
        <>

            <ProfileTokenComponent />
            <ChartTitle>
                <h2>Monthly revenue</h2>
                <h3>{siteName}</h3>
            </ChartTitle>
            {error && <p>Error: {error}</p>}
            {
                !data ? <RevenueLoader />
                :
                <>
                    <ChartFilter>
                        <SitesContainer>
                            <SitesSelect />
                        </SitesContainer>
                        <DatePickerComponent>

                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={pickedDate}
                                onChange={(date) => {
                                    setPickedDate(date);
                                    setStartDate(date);
                                }}
                                selectsStart
                                startDate={pickedDate}
                                endDate={endDate}
                                maxDate={endDate}

                            />
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={pickedDate}
                                endDate={endDate}
                                minDate={pickedDate}
                                maxDate={maxDate}
                            />

                            <button
                                onClick={handleButtonClick} className='btn btn-primary'
                            >
                                <img src={ArrowRight} alt="Update calendar" />
                            </button>

                        </DatePickerComponent>
                    </ChartFilter>

                    {loading ? (
                        <RevenueLoader />
                    ) : (
                        <>
                            <ChartDateWrapper>
                                {humanDate()}
                            </ChartDateWrapper>

                            <RevenueColorsWrapper>
                                <Chart
                                    id="daily-chart"
                                    className="revenueChart"
                                    type="bar"
                                    height={props.ChartHeight ? props.ChartHeight : 640}
                                    options={{
                                        ...chartData.options,
                                    }}
                                    series={data}
                                />
                            </RevenueColorsWrapper>
                        </>
                    )}
                </>
            }
        </>
    )
}

export default TotalChart;
